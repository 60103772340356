

 export const REACT_APP_API_URL= 'https://getehr.curegrid.co/api/caremeapp'
 export const AGORA_APP_ID = '99499b61fbef4cdd955aabd0bcd529fe' 
 export const REACT_APP_BASE_URL =  'https://video.curegrid.co'
 export const REACT_APP_BASEURL =  'https://getehr.curegrid.co/'
 export const RET_URL = `https://app.careme.health/paymentstatus`
 export const SITE_URL = 'https://careme.health'
 export const APP_URL = 'https://app.careme.health'
 export const B_URL='https://business.careme.health'
export const CURE_URL='https://getehr.curegrid.co/api'
export const REACT_APP_BU_API_URL =  'https://getehr.curegrid.co/api/business'
export const API_V_URL = 'https://getehr.curegrid.co/api/caremeapp/v1/'
export const CDN_URL = 'https://caremeh.blr1.digitaloceanspaces.com'
 
export const REACT_AGORA_APP_ID = process.env.REACT_AGORA_APP_ID

// export const AGORA_APP_ID = '99499b61fbef4cdd955aabd0bcd529fe'
//  export const REACT_APP_API_URL =  'https://getehr.synapstics.com/api/caremeapp'
//  export const REACT_APP_BU_API_URL =  'https://getehr.synapstics.com/api/business'
//  export const REACT_APP_BASE_URL =  'https://video.synapstics.com'
//  export const REACT_APP_BASEURL =  'https://getehr.synapstics.com/'
//  export const RET_URL =  `https://app.synapstics.com/paymentstatus`
//  export const SITE_URL = 'https://caremehealth.synapstics.com'
//  export const APP_URL = 'https://app.synapstics.com'
// export const B_URL='https://business.synapstics.com'
// export const CURE_URL='https://getehr.synapstics.com/api'
// export const API_V_URL = 'https://getehr.synapstics.com/api/caremeapp/v1/'

 
export  const headers={
  "Content-Type": "application/json",
  'Authorization': 'a7143772a09f993125901ea752bfd4fcf4b31d29'
 }

  export const REACT_APP_PROFILE_NAME_CHAR = 30
 
  export const getCurrencySymbol=(currencyCode) => {
    switch(currencyCode) {
      case "ALL":
        return "Lek";
      case "AFN":
        return "؋";
      case "ARS":
        return "$";
      case "AWG":
        return "ƒ";
      case "AUD":
        return "$";
      case "AZN":
        return "₼";
      case "BSD":
        return "$";
      case "BBD":
        return "$";
      case "BYN":
        return "Br";
      case "BZD":
        return "BZ$";
      case "BMD":
        return "$";
      case "BOB":
        return "$b";
      case "BAM":
        return "KM";
      case "BWP":
        return "P";
      case "BGN":
        return "лв";
      case "BRL":
        return "R$";
      case "BND":
        return "$";
      case "KHR":
        return "៛";
      case "CAD":
        return "$";
      case "KYD":
        return "$";
      case "CLP":
        return "$";
      case "CNY":
        return "¥";
      case "COP":
        return "$";
      case "CRC":
        return "₡";
      case "HRK":
        return "kn";
      case "CUP":
        return "₱";
      case "CZK":
        return "Kč";
      case "DKK":
        return "kr";
      case "DOP":
        return "RD$";
      case "XCD":
        return "$";
      case "EGP":
        return "£";
      case "EUR":
        return "€";
      case "FKP":
        return "£";
      case "FJD":
        return "$";
      case "GHS":
        return "¢";
      case "GIP":
        return "£";
      case "GTQ":
        return "Q";
      case "GGP":
        return "£";
      case "GYD":
        return "$";
      case "HNL":
        return "L";
      case "HKD":
        return "$";
      case "HUF":
        return "Ft";
      case "ISK":
        return "kr";
      case "INR":
        return "₹";
      case "IDR":
        return "Rp";
      case "IRR":
        return "﷼";
      case "IMP":
        return "£";
      case "ILS":
        return "₪";
      case "JMD":
        return "J$";
      case "JPY":
        return "¥";
      case "JEP":
        return "£";
      case "KZT":
        return "лв";
      case "KPW":
        return "₩";
      case "KRW":
        return "₩";
      case "KGS":
        return "лв";
      case "LAK":
        return "₭";
      case "LBP":
        return "£";
      case "LRD":
        return "$";
      case "MKD":
        return "ден";
      case "MYR":
        return "RM";
      case "MUR":
        return "₨";
      case "MXN":
        return "$";
      case "MNT":
        return "₮";
      case "MZN":
        return "MT";
      case "NAD":
        return "$";
      case "NPR":
        return "₨";
        case "ANG":
        return "ƒ";
      case "NZD":
        return "$";
      case "NIO":
        return "C$";
      case "NGN":
        return "₦";
      case "NOK":
        return "kr";
      case "OMR":
        return "﷼";
      case "PKR":
        return "₨";
      case "PAB":
        return "B/.";
      case "PYG":
        return "Gs";
      case "PEN":
        return "S/.";
      case "PHP":
        return "₱";
      case "PLN":
        return "zł";
      case "QAR":
        return "﷼";
      case "RON":
        return "lei";
      case "RUB":
        return "₽";
      case "SHP":
        return "£";
      case "SAR":
        return "﷼";
      case "RSD":
        return "Дин.";
      case "SCR":
        return "₨";
      case "SGD":
        return "$";
      case "SBD":
        return "$";
      case "SOS":
        return "S";
      case "KRW":
        return "₩";
      case "ZAR":
        return "R";
      case "LKR":
        return "₨";
      case "SEK":
        return "kr";
      case "CHF":
        return "CHF";
      case "SRD":
        return "$";
      case "SYP":
        return "£";
      case "TWD":
        return "NT$";
      case "THB":
        return "฿";
      case "TTD":
        return "TT$";
      case "TRY":
        return "₺";
      case "TVD":
        return "$";
      case "UAH":
        return "₴";
      case "AED":
        return "د.إ";
      case "GBP":
        return "£";
      case "USD":
        return "$";
      case "UYU":
        return "$U";
      case "UZS":
        return "лв";
      case "VEF":
        return "Bs";
      case "VND":
        return "₫";
      case "YER":
        return "﷼";
      case "ZWD":
        return "Z$";
      default:
        return "₹";
    }
  }