import logo from './logo.svg';
import React, { Component, Suspense } from "react";

import './assets/scss/main.scss'
import './App.css';
import "./i18n";

// import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './Components/Layouts/Login/Login';
import SessionTimeout from './utils/SessionTimeout';

function App() {
  return (
    <div className="App">
      <Login/>
      {/* <SessionTimeout /> */}
    </div>
  );
}

export default App;
