import React from "react";
import { ArrowRight, Calendar, PlayCircle } from "react-feather";
import {
  CDN_URL,
  REACT_APP_API_URL,
  REACT_APP_BASE_URL,
  REACT_APP_BU_API_URL,
  SITE_URL,
} from "../../processENV";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import moment from "moment";
import { withRouter } from "react-router";
import { RescheduleAction } from "../../../Actions/RescheduleAction";
import Store from "../../../Store";
import { ScheduleAction } from "../../../Actions/ScheduleAction";
import { ScheduleApp } from "../../../Actions/ScheduleApp";
import { TherapyJourneyAction } from "../../../Actions/TherapyJourneyAction";
import { Alert, Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import {
  BsCalendar2CheckFill,
  BsCheck2Circle,
  BsCircle,
  BsPersonBadge,
} from "react-icons/bs";
import CAreMEHEALTH from "../../../assets/images/CAreMEHEALTH.png";
import {
  FaBook,
  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
  FaMedium,
  FaPinterestSquare,
  FaTwitterSquare,
  FaVideo,
  FaYoutube,
  FaYoutubeSquare,
} from "react-icons/fa";
import { BsNewspaper } from "react-icons/bs";
import { postapi } from "../../../Helpers/Api";
const publicIp = require("react-public-ip");

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

class SelectedService extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: null,
      meetingId: "",
      profiledocname: "",
      serverTime: "",
      cancel: "",
      audioLock: false,
      confirmAppointment: [],
      pending: [],
      followUp: [],
      products: [],
      successfulPayment: [],
      therapyForms: [],
      pat_name: "",
      chat_item: [],
      selectP: "",
      old: false,
      new: false,
      empDetails: "",
      events_: [],
      eventsprev_: [],
      _wellbeing: false,
      _fol_therapist: false,
    };
  }

  componentDidMount() {
    let k = localStorage._patientKey;
    console.log(k.split("-")[2]);
    this.getSelectedAppointment();
    this.getNewProd();
    this.getFollowUp();
    this.getPending();
    this.getPatname();
    this.getPaid();
    this.getChat();
    this.getEvent();
    this.getTherapyAsses();
    this.interval = setInterval(() => this.getOrgTime(), 2000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getEvent = async () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
    };

    fetch(`${REACT_APP_BU_API_URL}/carme_events/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          console.log("events_", response);
          this.setState({ events_: response.alldata });
        }
      });
    fetch(`${REACT_APP_API_URL}/list_webinar/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response) {
          console.log("eventsprev_", response);
          this.setState({ eventsprev_: response.results });
        }
      });
  };

  getPatname = () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
    };
    fetch(`${REACT_APP_API_URL}/patientedit_app/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((res) => {
        this.setState({ pat_name: res.data.first_name });
      });
  };
  getTherapyAsses = () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
    };

    fetch(`${REACT_APP_API_URL}/home_therapyforms/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          console.log(response);
          this.setState({
            therapyForms: response.data,
            _wellbeing: response.welbeing,
          });
        }
      });
  };

  getOrgTime = async () => {
    fetch(`${REACT_APP_API_URL}/org_current_time/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          this.setState({ serverTime: response.data });
        }
      });
  };
  dateConversion = (rdate) => {
    let date = new Date(rdate);
    // console.log("dateee",date.getDate() + " " + monthNames[date.getMonth()]  + "," +  date.getFullYear()  + "at" +  date.getHours() + ":" + (date.getMinutes() == "0" ? date.getMinutes() + "0" :date.getMinutes()) )
    return (
      date.getDate() +
      " " +
      monthNames[date.getMonth()] +
      "," +
      date.getFullYear() +
      " at " +
      date.getHours() +
      ":" +
      (date.getMinutes() == "0" ? date.getMinutes() + "0" : date.getMinutes()) +
      " " +
      (parseInt(date.getHours()) < 12 ? "AM" : " PM")
    );
  };

  dateConversionTherapy = (rdate) => {
    let date = new Date(rdate);
    // console.log("dateee",date.getDate() + " " + monthNames[date.getMonth()]  + "," +  date.getFullYear()  + "at" +  date.getHours() + ":" + (date.getMinutes() == "0" ? date.getMinutes() + "0" :date.getMinutes()) )
    return (
      date.getDate() +
      " " +
      monthNames[date.getMonth()] +
      "," +
      date.getFullYear()
    );
  };

  cancelAppointment = (aKey, reason) => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
      appointment_n_key: aKey,
      status: "Cancelled",
      cancelled_reason: reason,
    };
    console.log(data);
    fetch(`${REACT_APP_API_URL}/app_cancel_appoint/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("cancel appointment", res.data);
        if (res.status === "success") {
          this.getSelectedAppointment();
        } else {
          console.log("cancel appointment", res.data);
        }
      });
  };
  scheduleApp = async(aKey, id, key) => {
    let item = key;
    console.log(aKey, id, key);
    if (item.app_prod_grp_key) {
      let data = {
        patient_n_key: localStorage._patientKey,
        app_prod_grp_key: item.app_prod_grp_key,
      };
      const res = await postapi('/previous_check/',data)
          if (res.status == "success") {
            if (res.data[0].is_active == 1) {
              this.setState({ _fol_therapist: false });
              let appointmentDetail = {
                doc_app_id: res.data[0].employee_n_key,
                app_prod_grp_key: item.app_prod_grp_key,
                app_payment_n_key: item.app_payment_n_key,
                addon: item.addon || false,
              };
              Store.dispatch(
                ScheduleApp({
                  data1: appointmentDetail,
                })
              );
              this.props.history.push(`/scheduleapp/followup/id`, {
                data1: appointmentDetail,
              });
            } else {
              this.props.history.push("/creditsleft", {
                selectP: item,
                creditModal: true,
                empDetails: res.data,
                _fol_therapist: true,
              });
            }
          } else {
            Store.dispatch(
              ScheduleApp({
                data1: item,
              })
            );
            this.props.history.push(`/scheduleapp/followup/${id}`, {
              data1: item,
            });
          }
    }
  };
  rescheduleAppointment = (item) => {
    console.log(item)
    if(item.overall_status==="Rescheduled"){
      Swal.fire({
        html: `<p style="font-size: 14px; line-height: 1.5;">
                 Appointment can be rescheduled once!<br>
                 Please contact support for further help in appointment booking.
               </p>`
      });  
     }
    else{
    Store.dispatch(
      RescheduleAction({
        prodId: item,
      })
    );
    this.props.history.push(`/reschedule/appointment/date`, { data: item });
  }
  };
  getStep=(title)=>{
   return 0
  }
  getApprodKey=(title)=>{
    switch (title) {
      case "Couple Therapy":
        return { app_prod_n_key: "APP_PROD-5" };
      case "Psychiatric Consultation":
        return { app_prod_n_key: "APP_PROD-3" };
      default:
        return null;
    }
  
  }
  scheduleAppointment = async(item) => {
    console.log(item);
    if (item.app_prod_grp_key) {
      let data = {
        patient_n_key: localStorage._patientKey,
        app_prod_grp_key: item.app_prod_grp_key,
      };
      const res= await postapi('/previous_check/',data)
          if (res.status == "success") {
            if (res?.data[0]?.is_active !== 1) {
              let appointmentDetail = {
                doc_app_id: res.data[0].employee_n_key,
                app_prod_grp_key: item.app_prod_grp_key,
                app_payment_n_key: item.app_payment_n_key,
                previous_book: "Yes",
                addon: item.addon || false,
              };
              Store.dispatch(
                ScheduleApp({
                  data1: appointmentDetail,
                })
              );
              this.props.history.push(`/scheduleapp/followup/id`, {
                data1: appointmentDetail,
              });
            } 
            else {
              this.props.history.push("/creditsleft", {
                selectP: item,
                creditModal: true,
                empDetails: res.data,
              });
            }
          } 
          else {
              Store.dispatch(
                ScheduleAction({
                  scheduleStep: this.getStep(item.title),
                  scheduleCurrentStep: this.getStep(item.title),
                  problem: this.getApprodKey(item.title),
                  mode: "Video",
                  data1: item,
                })
              );
            this.props.history.push("/schedule/appointment", { data1: item });
          }
    }
  };
  sessionPay = async(item, index) => {
    console.log(item)
    let data = {
      patient_n_key: localStorage._patientKey,
      app_prod_grp_key: item.app_prod_grp_key,
    };
    const res = await postapi('/previous_check/',data)
        if (res.status == "success") {
          if (res.data[0].is_active == 1) {
            Store.dispatch(
              TherapyJourneyAction({
                indx: index,
              })
            );
            localStorage.setItem("_i", index);
            this.props.history.push(`/therapyjourney/selectSession/`);
          }
        else{
          this.props?.history?.push('/therapistleft',{data:item,empDetails:res.data})
        }
        }
  };
  getNewProd = async () => {
    let data = {
      ip: (await publicIp.v4()) || "",
      patient_n_key: localStorage.getItem("_patientKey"),
    };
    fetch(`${REACT_APP_API_URL}/products_groups/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("new prod", res.data);
        if (res.status === "success") {
          this.setState({ products: res.data });
        } else {
          console.log("new prod", res.data);
        }
      });
  };
  getFollowUp = () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
    };

    fetch(`${REACT_APP_API_URL}/upcoming_recommended/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("followup service", res.data);
        if (res.status === "success") {
          this.setState({ followUp: res.data });
        } else {
          console.log("followup service", res.data);
        }
      });
  };
  getPaid = () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
    };

    fetch(`${REACT_APP_API_URL}/upcoming_payment/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("paid service", res.data);
        if (res.status === "success") {
          this.setState({ successfulPayment: res.data });
        } else {
          console.log("pending service", res.data);
        }
      });
  };
  getPending = () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
    };

    fetch(`${REACT_APP_API_URL}/upcoming_followups/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("pending service", res.data);
        if (res.status === "success") {
          this.setState({ pending: res.data });
        } else {
          console.log("pending service", res.data);
        }
      });
  };

  getSelectedAppointment = () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
    };

    fetch(`${REACT_APP_API_URL}/upcoming_appoints/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("selected service", res.data);
        if (res.status === "success") {
          this.setState({ confirmAppointment: res.data });
        } else {
          console.log("selected service", res.data);
        }
      });
  };
  changeAudio() {
    const timer = setTimeout(() => {
      this.setState({ audioLock: false });
    }, 240000);
    return () => clearTimeout(timer);
  }

  getChat = async () => {
    let data = { patient_n_key: localStorage._patientKey };
    fetch(REACT_APP_API_URL + "/app_chathome/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("chat_det", res);
        if (res.status === "success") {
          this.setState({ chat_item: res });
        } else {
          console.log("meeting id", res.data);
        }
      });
  };

  async joinFreeChat() {
    const pt_key = localStorage._patientKey;
    const JsonData = {
      patient_n_key: localStorage._patientKey,
    };
    fetch(REACT_APP_API_URL + "/counselling_room/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(JsonData),
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status === "success") {
          let JsonData1 = {
            channelName: res.channelid,
            uid: pt_key,
          };
          fetch(REACT_APP_API_URL + "/agora_rtctoken/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
            },
            body: JSON.stringify(JsonData1),
          })
            .then((response) => response.json())
            .then(async (resp) => {
              if (resp.status == "success") {
                localStorage.uid = pt_key;
                localStorage.channelName = res.channelid;
                this.props.history.push("/chatscreen", {
                  channelId: res.channelid,
                  token: resp.rtm_data,
                  uid: pt_key,
                  typeofchat: "CounsellingRoom",
                  freeChat: res,
                  firstChat: res.new_user,
                  doctor_photo:
                    this.state.chat_item &&
                    this.state.chat_item?.support_chat &&
                    this.state.chat_item?.support_chat.length !== 0
                      ? this.state.chat_item?.support_chat[0]?.doctor_photo
                      : null,
                  doctor_name:
                    this.state.chat_item &&
                    this.state.chat_item?.support_chat &&
                    this.state.chat_item?.support_chat.length !== 0
                      ? this.state.chat_item.support_chat[0]?.doctor_name
                      : null,
                  disable:
                    this.state.chat_item &&
                    this.state.chat_item?.support_chat &&
                    this.state.chat_item?.support_chat.length !== 0
                      ? this.state.chat_item.support_chat[0]?.expired
                      : false,
                });
              }
            });
        }
      });
  }

  getMeetingid = (item, id) => {
    if (item.mode_of_telehealth === "Video") {
      let data = {
        patient_n_key: localStorage.getItem("_patientKey"),
        appointment_n_key: id,
      };
      fetch(`${REACT_APP_API_URL}/join_now_app/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then(async (res) => {
          console.log("meeting id", res.data);
          if (res.status === "success") {
            this.setState({ meetingId: res.data });
            let id = res.data.split("/")[4];
            console.log("meeting Id", id);
            window.location.replace(`${REACT_APP_BASE_URL}/patient/` + id);
          } else {
            console.log("meeting id", res.data);
          }
        });
    }
    if (item.mode_of_telehealth === "Audio") {
      this.setState({ audioLock: true }, () => {
        this.changeAudio();
      });
      var now_time = "";
        fetch(`${REACT_APP_API_URL}/org_current_time/`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
          },
        })
  .then((response) => response.json())
  .then((response) => {
    if (response.status === "success") {
      now_time = response.data;
      var meetingTime = "";
      meetingTime = item.appointment_date;
      const currentTime = moment(response.data).toDate();
      console.log("This_is_current_time", currentTime);
      console.log(moment(this.state.serverTime).toDate());
      var meetingDate = moment(meetingTime).toDate();
      let differenceTIme =
        (meetingDate.getTime() - currentTime.getTime()) / 1000; //- (19800) //minus offset time 330 mins *60
      var differenceSeconds = parseInt(differenceTIme) / 60;
      if(differenceSeconds <= 10){
        let data = {
          org_n_key: "ORGID-1",
          appointment_n_key: id,
        };
        fetch(`https://getehr.curegrid.co/api/connect_audio/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
          },
          body: JSON.stringify(data),
        })
          .then((response) => response.json())
          .then(async (res) => {
            if (res.status === "success") {
              console.log("audio id", res);
            } else {
              console.log("meeting id", res.data);
            }
          });
      }
      else {alert(`Your Appointmet will beign at ${moment(item.appointment_date).format('DD-MM-YYYY')} at ${item.appointment_time}`)}
    }
  })
  .catch((error) => {
    console.error("Error fetching current time:", error);
  });

  
    }
    if (item.mode_of_telehealth === "Chat") {
      var now_time = "";
      fetch(`${REACT_APP_API_URL}/org_current_time/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            now_time = response.data;
            var meetingTime = "";
            meetingTime = item.appointment_date;
            const currentTime = moment(response.data).toDate();
            console.log("This_is_current_time", currentTime);
            console.log(moment(this.state.serverTime).toDate());
            var meetingDate = moment(meetingTime).toDate();
            let differenceTIme =
              (meetingDate.getTime() - currentTime.getTime()) / 1000; //- (19800) //minus offset time 330 mins *60
            var differenceSeconds = parseInt(differenceTIme) / 60;

            if (differenceSeconds <= 10) {
              let JsonData1 = {
                channelName: item.appointment_n_key,
                uid: localStorage._patientKey,
              };
              fetch(REACT_APP_API_URL + "/agora_rtctoken/", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
                },
                body: JSON.stringify(JsonData1),
              })
                .then((response) => response.json())
                .then(async (resp) => {
                  if (resp.status == "success") {
                    localStorage.uid = localStorage._patientKey;
                    localStorage.channelName = item.appointment_n_key;
                    this.props.history.push("/chatscreen", {
                      channelId: item.appointment_n_key,
                      empKey: item?.doc_app_id,
                      token: resp.rtm_data,
                      uid: item.patient_n_key,
                      mode: item.mode_of_telehealth,
                      typeofchat: "TeleHealth",
                      planitem: item,
                      doctor_photo: item.doctor_profile,
                      doctor_name: item.doctor_name,
                    });
                  }
                });
            } else {
              this.props.history.push("/waitingscreen", {
                item: item,
                typeofchat: "TeleHealth",
              });
            }
          }
        });
    }
  };
  cancelReason = async (item) => {
    const { value: text } = await Swal.fire({
      input: "textarea",
      inputLabel: "Could you tell us the reason for cancellation",
      inputPlaceholder: "Type your message here...",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      customClass: {
        validationMessage: "my-validation-message",
      },
      showCancelButton: true,
      preConfirm: (value) => {
        if (value.length <= 15) {
          Swal.showValidationMessage(
            " Reason should be atleast 15 characters!"
          );
        } else {
          this.cancelAppointment(item && item.appointment_n_key, value);
        }
      },
    });
  };
  goToPayment = async (i, title) => {
    const { confirmAppointment, successfulPayment, followUp, pending } =
      this.state;
    let k_ = localStorage.getItem("_patientKey");
    const ptkey = k_.split("-")[2];
    const initial = "therapy";
    console.log(i, title);
    if (title == "APP-PROD-CAT-1") {
      if (
        confirmAppointment.length !== 0 &&
        confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
          .length !== 0
      ) {
        confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
          .length !== 0
          ? this.getMeetingid(
              confirmAppointment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-1"
              )[0],
              confirmAppointment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-1"
              )[0].appointment_n_key
            )
          : console.log("no_confirmdata");
      } else if (
        successfulPayment.length !== 0 &&
        successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
          .length !== 0
      ) {
        successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
          .length !== 0
          ? this.scheduleAppointment(
              successfulPayment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-1"
              )[0]
            )
          : console.log("no_paydata");
      } else if (
        followUp.length !== 0 &&
        followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
          .length !== 0
      ) {
        followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
          .length !== 0
          ? this.sessionPay(
              followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")[0],
              0
            )
          : console.log("no_therapydata");
      } else if (
        pending.length !== 0 &&
        pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1").length !==
          0
      ) {
        pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1").length !==
        0
          ? this.scheduleApp(
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")[0]
                .appointment_n_key,
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")[0]
                .doc_app_id,
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")[0]
            )
          : console.log("no_sessiondata");
      } else if (
        (pending.length !== 0 &&
          pending?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-1")
          )) ||
        (confirmAppointment.length !== 0 &&
          confirmAppointment?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-1")
          )) ||
        (successfulPayment.length !== 0 &&
          successfulPayment?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-1")
          )) ||
        (followUp.length !== 0 &&
          followUp?.some((i) => i.app_prod_grp_key?.includes("APP-PROD-CAT-1")))
      ) {
        console.log("yes");
      } else {
        // window.location.assign(`http://careme.health/${ptkey}/individual-therapy/${initial}`)
        window.location.assign(
          SITE_URL + `/find/user/${ptkey}/individual-therapy/${initial}`
        );
      }
    }
    if (title == "APP-PROD-CAT-2") {
      if (
        confirmAppointment.length !== 0 &&
        confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
          .length !== 0
      ) {
        confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
          .length !== 0
          ? this.getMeetingid(
              confirmAppointment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-2"
              )[0],
              confirmAppointment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-2"
              )[0].appointment_n_key
            )
          : console.log("no_confirmdata");
      } else if (
        successfulPayment.length !== 0 &&
        successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
          .length !== 0
      ) {
        successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
          .length !== 0
          ? this.scheduleAppointment(
              successfulPayment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-2"
              )[0]
            )
          : console.log("no_paydata");
      } else if (
        followUp.length !== 0 &&
        followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
          .length !== 0
      ) {
        followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
          .length !== 0
          ? this.sessionPay(
              followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")[0],
              0
            )
          : console.log("no_therapydata");
      } else if (
        pending.length !== 0 &&
        pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2").length !==
          0
      ) {
        pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2").length !==
        0
          ? this.scheduleApp(
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")[0]
                .appointment_n_key,
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")[0]
                .doc_app_id,
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")[0]
            )
          : console.log("no_sessiondata");
      } else if (
        (pending.length !== 0 &&
          pending?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-2")
          )) ||
        (confirmAppointment.length !== 0 &&
          confirmAppointment?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-2")
          )) ||
        (successfulPayment.length !== 0 &&
          successfulPayment?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-2")
          )) ||
        (followUp.length !== 0 &&
          followUp?.some((i) => i.app_prod_grp_key?.includes("APP-PROD-CAT-2")))
      ) {
        console.log("yes");
      } else {
        // window.location.assign(`http://careme.health/${ptkey}/couple-therapy/${initial}`)
        window.location.assign(
          SITE_URL + `/find/user/${ptkey}/couple-therapy/${initial}`
        );
      }
    }
    if (title == "APP-PROD-CAT-3") {
      if (
        confirmAppointment &&
        confirmAppointment.length !== 0 &&
        confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
          .length !== 0
      ) {
        confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
          .length !== 0
          ? this.getMeetingid(
              confirmAppointment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-3"
              )[0],
              confirmAppointment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-3"
              )[0].appointment_n_key
            )
          : console.log("no_confirmdata");
      } else if (
        successfulPayment &&
        successfulPayment.length !== 0 &&
        successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
          .length !== 0
      ) {
        successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
          .length !== 0
          ? this.scheduleAppointment(
              successfulPayment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-3"
              )[0]
            )
          : console.log("no_paydata");
      } else if (
        followUp &&
        followUp.length !== 0 &&
        followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
          .length !== 0
      ) {
        followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
          .length !== 0
          ? this.sessionPay(
              followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")[0],
              0
            )
          : console.log("no_therapydata");
      } else if (
        pending &&
        pending.length !== 0 &&
        pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3").length !==
          0
      ) {
        pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3").length !==
        0
          ? this.scheduleApp(
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")[0]
                .appointment_n_key,
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")[0]
                .doc_app_id,
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")[0]
            )
          : console.log("no_sessiondata");
      } else if (
        (pending.length !== 0 &&
          pending?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-3")
          )) ||
        (confirmAppointment.length !== 0 &&
          confirmAppointment?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-3")
          )) ||
        (successfulPayment.length !== 0 &&
          successfulPayment?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-3")
          )) ||
        (followUp.length !== 0 &&
          followUp?.some((i) => i.app_prod_grp_key?.includes("APP-PROD-CAT-3")))
      ) {
        console.log("yes");
      } else {
        window.location.assign(
          SITE_URL + `/find/user/${ptkey}/psychiatric-consultation/${initial}`
        );
      }
    }
  };
  showbutton = (item) => {
    let nowDate = moment(this.state.serverTime).toDate();
    let appDate = moment(item.appointment_date).toDate();
    var diff = (appDate.getTime() - nowDate.getTime()) / 1000;
    var differenceSeconds = parseInt(diff) / 60;
    if (differenceSeconds >= 60) {
      return true;
    } else return false;
  };
  showAppointment = (item) => {
    let nowDate = moment(this.state.serverTime).toDate();
    let appDate = moment(item.appointment_date).toDate();
    var diff = (appDate.getTime() - nowDate.getTime()) / 1000;
    var differenceSeconds = parseInt(diff) / 60;
    if (differenceSeconds >= -180) {
      return true;
    } else return false;
  };
  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };
  render() {
    if (
      this.state.doctor_profile === "" ||
      this.state.doctor_profile === null
    ) {
      var docname = this.state.doctor_name;
      var res = docname.charAt(0);
      this.setState({
        profiledocname: res,
      });
    }
    let {
      confirmAppointment,
      successfulPayment,
      creditModal,
      products,
      followUp,
      pending,
      therapyForms,
      _wellbeing,
      _welluser,
      events_,
      eventsprev_,
    } = this.state;
    const ptkey = localStorage.getItem("_patientKey");

    return (
      <div
        className="container m-0 p-md-0 px-4 position-relative"
        style={{ overflowY: "scroll", marginTop: 20 }}
      >
        <div className="col-md-12 mx-auto">
          {this.state.pat_name && (
            <div className="mt-md-5 mt-5 pl-md-5 pl-1 pt-md-2 pt-5">
              <h2
                style={{ fontFamily: "Source Serif Pro" }}
                className="font-weight-bold ml-md-5 ml-1 pl-md-5 pl-1"
              >
                Hi, {this.capitalize(this.state.pat_name)} !
              </h2>
            </div>
          )}
        </div>
        {therapyForms && therapyForms.length !== 0 ? (
          <>
            <div
              class="mx-auto col-md-5 p-4 d-flex align-items-center position-relative shadow rounded mt-5 "
              style={{ background: "rgb(248, 245, 231)" }}
            >
              <span
                style={{
                  top: "2px",
                  left: "14px",
                  fontSize: "15px",
                  color: "orange",
                  fontWeight: "bold",
                }}
                className="position-absolute"
              >
                Required
              </span>
              <p className="text-left pt-3">
                Your Clinician has assigned you an assessment - { therapyForms[0]?.form_name}, tap here to
                complete
              </p>
              <button
                className="shadow rounded p-0 ml-2"
                style={{
                  background: "rgb(253, 131, 109)",
                  height: "40px",
                  fontWeight: "bold",
                  minWidth: "90px",
                  border: "none",
                  color: "rgb(255, 255, 255)",
                }}
                onClick={() =>
                  this.props.history.push("therapyAsses", {
                    order_form_id: therapyForms[0].order_form_id,
                  })
                }
              >
                Take Now
              </button>
            </div>
          </>
        ) : null}
        {!_wellbeing ? (
          <>
            <div className="col-md-5 mx-auto shadow-sm mt-md-5 mt-4 p-4 bg-white rounded">
              <h4 className="header_font_">
                Unlock your inner balance with our brief Well-Being Assessment!
              </h4>
              <button
                style={{ background: "#215352", color: "#fff" }}
                className="btn btn-block mt-4 mx-auto font-weight-bold"
                onClick={() => {
                  window.location.href = "/wellbeing-profile";
                }}
              >
                Tap here to start
              </button>
            </div>
          </>
        ) : null}

        <Modal centered lg show={creditModal}>
          {/* <ModalHeader style={{textAlign:'center'}} >Choose an option to continue with your appointment</ModalHeader> */}
          <ModalBody style={{ textAlign: "center" }}>
            {this.state.empDetails[0] &&
            this.state.empDetails[0].is_active == 0 ? (
              <div className="prec_check_ text_p_ font-weight-bold pt-5">
                Hey, we recently had to let our therapist{" "}
                {`${
                  (this.state.empDetails[0] &&
                    this.state.empDetails[0].first_name) +
                  " " +
                  (this.state.empDetails[0] &&
                    this.state.empDetails[0].last_name)
                }`}{" "}
                go. If you would like to continue with her/him, send an email{" "}
                <span style={{ textDecorationLine: "underline" }}>
                  {this.state.empDetails[0] &&
                    this.state.empDetails[0].email_id}
                </span>{" "}
                or call{" "}
                <span style={{ textDecorationLine: "underline" }}>
                  {this.state.empDetails[0] &&
                    this.state.empDetails[0].phone_number}{" "}
                </span>
                <br />
                <div>
                  <br />
                  <p className="text_p_">
                    You're not alone and we're here for you. We have therapists
                    who can help you rebuild your life, learn and grow from the
                    experience, and get back to where you were before this
                    happened. Please reach out if you are ready to begin.
                  </p>
                </div>
                <br />
                <span style={{ fontSize: 12 }}>
                  Our support number: +91 7829-002-002
                </span>
                <div
                  style={{ width: "70%" }}
                  onClick={() => this.setState({ old: false, new: true })}
                  className="prec_check mt-4 border rounded text-center mx-auto py-3"
                >
                  {this.state.new ? (
                    <BsCheck2Circle
                      size={25}
                      style={{ marginRight: "4px" }}
                      className="icn_span"
                    />
                  ) : (
                    <BsCircle
                      style={{ marginRight: "4px" }}
                      size={15}
                      className="icn_span"
                    />
                  )}{" "}
                  I would like to switch therapist
                </div>
              </div>
            ) : (
              <div
                onClick={() => this.setState({ old: true, new: false })}
                className="prec_check mt-4 border rounded"
              >
                {this.state.old ? (
                  <BsCheck2Circle size={30} className="icn_span" />
                ) : (
                  <BsCircle size={15} className="icn_span" />
                )}{" "}
                I would like to continue with my previous therapist
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.setState({ creditModal: false })}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={this.state.old || this.state.new ? false : true}
              onClick={() => {
                this.setState({ creditModal: false });
                if (this.state.old) {
                  console.log("d");
                }
                if (this.state.new) {
                  if (this.state._fol_therapist) {
                    Store.dispatch(
                      ScheduleAction({
                        data1: this.state.selectP,
                      })
                    );
                    this.props.history.push("/schedule/appointment", {
                      data1: this.state.selectP,
                      _fol_therapist: true,
                    });
                  } else {
                    if (
                      this.state.selectP.title === "Psychiatric Consultation"
                    ) {
                      let selectedProblem = { app_prod_n_key: "APP_PROD-3" };
                      Store.dispatch(
                        ScheduleAction({
                          scheduleStep: 1,
                          scheduleCurrentStep: 1,
                          problem: selectedProblem,
                          mode: "Video",
                          data1: this.state.selectP,
                        })
                      );
                    } else {
                      Store.dispatch(
                        ScheduleAction({
                          data1: this.state.selectP,
                        })
                      );
                    }
                    this.props.history.push("/schedule/appointment", {
                      data1: this.state.selectP,
                    });
                  }
                }
              }}
            >
              Continue
            </Button>{" "}
          </ModalFooter>
        </Modal>

        {/* {confirmAppointment.length !==0 &&   <h2 className='upcome_appoint'>Upcoming Appointments</h2>} */}
        {confirmAppointment.length !== 0 &&
          confirmAppointment.map((item, index) => {
            return (
              this.showAppointment(item) && (
                <div className="col-md-5 mx-auto draw shadow rounded p-4 bg-white mt-5 mb-3">
                  <div className="row px-3 text-center">
                    <img
                      style={{ width: "65px", height: "65px" }}
                      src={
                        `${CDN_URL}/APP/appointment.png`
                      }
                    />
                    <h3
                      className="pl-1 pt-4"
                      style={{
                        fontFamily: "Source Serif Pro",
                        fontSize: "18px",
                      }}
                    >
                      Appointment Confirmed!
                    </h3>
                  </div>
                  <div className="row px-3 mt-2">
                    <p className="text_p_ text_medium_">
                      We're looking forward to speaking with you,{" "}
                      {item.patient_name}! Your upcoming session has been
                      scheduled at{" "}
                      {this.dateConversion(item && item.appointment_date)} with{" "}
                      {item.doctor_name}
                    </p>
                  </div>
                  <div className="row px-3 text-center mt-2 d-flex flex-row justify-content-center">
                    {item.mode_of_telehealth === "Video" && (
                      <button
                        className={`btn  m-1 ${
                          !this.showbutton(item) ? "btn-block" : ""
                        } ${
                          !this.showbutton(item)
                            ? "btn_Schdule"
                            : "btn_Schdules"
                        }`}
                        onClick={() =>
                          this.getMeetingid(
                            item,
                            item && item.appointment_n_key
                          )
                        }
                      >
                        Join Call
                      </button>
                    )}
                    {item.mode_of_telehealth === "Audio" && (
                      <button
                        className={`btn m-1 ${
                          !this.showbutton(item) ? "btn-block" : ""
                        } ${
                          !this.showbutton(item)
                            ? "btn_Schdule"
                            : "btn_Schdules"
                        }`}
                        onClick={() =>
                          this.getMeetingid(
                            item,
                            item && item.appointment_n_key
                          )
                        }
                      >
                        Call Now
                      </button>
                    )}
                    {item.mode_of_telehealth === "Chat" && (
                      <button
                        className={`btn  m-1 ${
                          !this.showbutton(item) ? "btn-block" : ""
                        } ${
                          !this.showbutton(item)
                            ? "btn_Schdule"
                            : "btn_Schdules"
                        }`}
                        onClick={() =>
                          this.getMeetingid(
                            item,
                            item && item.appointment_n_key
                          )
                        }
                      >
                        Join Chat
                      </button>
                    )}
                    {this.showbutton(item) && (
                      <button
                        className="btn btn_Schdules m-1"
                        style={{ fontSize: "10px" }}
                        onClick={() => this.rescheduleAppointment(item && item)}
                      >
                        Reschedule
                      </button>
                    )}
                    {this.showbutton(item) && (
                      <button
                        className="btn btn_Schdules m-1"
                        style={{ fontSize: "10px" }}
                        onClick={() => this.cancelReason(item)}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                </div>
              )
            );
          })}

        {/* { confirmAppointment.length==0 && successfulPayment.length==0 && pending.length !== 0 &&  <h2 style={{fontFamily:'Source Serif Pro'}}  className='text-center mt-5 pt-5 mb-3'> Book your Follow-up Session</h2>} */}
        {confirmAppointment.length == 0 &&
          successfulPayment.length == 0 &&
          pending.length !== 0 &&
          pending.map((item, index) => {
            return (
              <div className="col-md-5 mx-auto draw shadow rounded p-4 bg-white mt-5 mb-3">
                <div className="row px-3 text-center">
                  <img
                    style={{ width: "35px", height: "35px" }}
                    src={
                      `${CDN_URL}/APP/calendar1.png`
                    }
                  />
                  <h3
                    className="pt-2 pl-2"
                    style={{ fontFamily: "Source Serif Pro" }}
                  >
                    <b>{item.remaining_session}</b> Upcoming Sessions
                  </h3>
                </div>
                <div className="row px-3 mt-2">
                  <p className="text_p_ text_medium_">
                    That's Awesome! you have completed{" "}
                    {item.total_session - item.remaining_session} sessions out
                    of {item.total_session}, let's progress towards your goals,
                    re-evaluating along the way-by your therapist!
                  </p>
                </div>
                <div className="row px-3 text-center mt-2">
                  <button
                    className="btn btn-block btn_Schdule"
                    onClick={() =>
                      this.scheduleApp(
                        item && item.appointment_n_key,
                        item.doc_app_id,
                        item
                      )
                    }
                  >
                    Schedule Now
                  </button>
                </div>
              </div>
            );
          })}
        {/* {confirmAppointment.length==0 && successfulPayment.length==0 && pending.length==0&& followUp.length !== 0 &&  <h2  className='upcome_appoint'>Book Your Follow-up Appointments</h2>} */}
        {confirmAppointment.length == 0 &&
          successfulPayment.length == 0 &&
          pending.length == 0 &&
          followUp.length !== 0 &&
          followUp.map((item, index) => {
            return (
              <div className="col-md-5 mx-auto draw shadow rounded p-4 bg-white mt-5 mb-3">
                <div className="row px-3 text-center">
                  <img
                    style={{ width: "55px", height: "55px" }}
                    src={
                      `${CDN_URL}/APP/app/measring.png`
                    }
                  />
                  <h3
                    className="pt-3 pl-2"
                    style={{ fontFamily: "Source Serif Pro" }}
                  >
                    Book Your Folloup
                  </h3>
                </div>
                <div className="row px-3 mt-2">
                  <p className="text_p_ text_medium_">
                    Your therapist {item.doctor_name} has crafted a customized
                    care plan for you! Now You can work with a therapist to
                    practice standing your ground, speaking up, or improving
                    relationships, all of which can improve your quality of
                    life.
                  </p>
                </div>
                <div className="row px-3 text-center mt-2">
                  <button
                    className="btn btn-block btn_Schdule"
                    onClick={() => this.sessionPay(item, index)}
                  >
                    View my care plan
                  </button>
                </div>
              </div>
            );
          })}
        {/* {confirmAppointment.length ==0 && successfulPayment.length !== 0 && successfulPayment.length !==0 &&  <h2 className='upcome_appoint'>Schedule Your Appointments</h2>} */}
        {confirmAppointment.length == 0 &&
          successfulPayment.length !== 0 &&
          successfulPayment.map((item, index) => {
            return (
              <div className="col-md-5 mx-auto draw shadow rounded p-4 bg-white mt-5 mb-3">
                <div className="row px-3 text-center">
                  <img
                    style={{ width: "65px", height: "65px" }}
                    src={
                     `${CDN_URL}/APP/appointment.png`
                    }
                  />
                  <h3
                    className="pt-3 pl-2"
                    style={{ fontFamily: "Source Serif Pro" }}
                  >
                    <b>{item.remaining_session}</b> Book your session
                  </h3>
                </div>
                <div className="row px-3 mt-2">
                  <p className="text_p_ text_medium_">
                    We've received your payment and our therapist is waiting for
                    you, click book now to book your appointment
                  </p>
                </div>
                <div className="row px-3 text-center mt-2">
                  <button
                    className=" btn btn-block btn_Schdule"
                    onClick={() => this.scheduleAppointment(item && item)}
                  >
                    Book Now
                  </button>
                </div>
              </div>
            );
          })}

        <div
          style={{ borderRadius: "12px" }}
          className="col-md-10 ml-auto mr-md-5 draw shadow-sm p-4 bg-white mt-5 mb-3"
        >
          <div className="d-flex flex-row ">
            <div className="ml-md-3 ml-1 pt-3">
              <h5
                style={{ fontSize: "15px" }}
                className="_text_color text-left text_p_ font-weight-bold"
              >
                RECOMMENDED
              </h5>
              <h1 className="_text_color text-left header_font_">
                Start 1-to-1 sessions
              </h1>
              <p className="_text_color text-left text_p_ col-md-8 ml-0 p-0">
                Get matched with coach or therapist for recurring 1-to-1
                sessions to work on your focus topic,{" "}
                <span style={{ fontWeight: "bold" }}>
                  mindfulness and meditation
                </span>
                .
              </p>
              <button
                onClick={() => {
                  window.location.href = "/offerings";
                }}
                className="btn_Schdules"
              >
                Get matched
              </button>
            </div>
            <div className="d-none d-sm-block">
              <img
                style={{ width: "435px", height: "225px" }}
                src={
                  `${CDN_URL}/Websiteicons/iccons.png`
                }
              />
            </div>
          </div>
        </div>

        <div
          style={{ borderRadius: "6px" }}
          className="col-md-10 ml-auto mr-md-5 p-1 mt-5 mb-3"
        >
          <h3 style={{ fontSize: "25px" }} className="header_font_ _text_color">
            <BsCalendar2CheckFill size={25} />{" "}
            {events_[0] ? "Upcoming Events" : "Events"}
          </h3>
          <div className="d-flex flex-md-row flex-column  mt-3">
            {events_ && events_.length > 0 ? (
              <div className="d-flex flex-md-row flex-column bg-white col-md-8 draw shadow-sm rounded-lg">
                <div className="flex-fill col-md-5 align-self-center">
                  <img
                    className="rounded d-lg-none d-block  img-fluid"
                    src={events_[0].image_url || CAreMEHEALTH}
                  />
                  <img
                    className="rounded img-fluid  d-none d-sm-block align-self-center"
                    src={events_[0].image_url || CAreMEHEALTH}
                    style={{}}
                  />
                </div>
                <div className="col-md-7 flex-fill">
                  <div className="d-flex flex-fill flex-row py-1">
                    <p
                      style={{ fontSize: "12px" }}
                      className="text_p_ _text_color border rounded p-2 mt-3 ml-3"
                    >
                      <Calendar size={14} />{" "}
                      {moment(events_[0].event_start, "YYYY-MM-DD hA").format(
                        "Do MMMM YYYY, hA"
                      )}
                    </p>
                    <p
                      style={{ fontSize: "12px" }}
                      className="text_p_ _text_color border rounded p-2 mt-3 ml-3"
                    >
                      <PlayCircle size={14} /> Video session{" "}
                    </p>
                  </div>
                  <h3 className="header_font_ _text_color ml-3 mt-n3 pt-2">
                    {events_[0].event_name}
                  </h3>
                  <p className="text_p_ ml-3 _text_color">
                    {events_[0].comments.length > 30
                      ? events_[0].comments.slice(0, 132) + "..."
                      : events_[0].comments}
                  </p>
                  <hr className="ml-3" style={{ color: "#dcdcdc" }} />
                  <h5
                    style={{ opacity: 0.6 }}
                    className="d-flex ml-3 header_font_ _text_color"
                  >
                    <BsPersonBadge /> {events_[0].host}
                  </h5>
                  <button
                    className="btn_Schdules ml-3 mb-3 mt-2 rounded"
                    onClick={() => {
                      window.open(events_[0].url);
                    }}
                  >
                    Join Event
                  </button>
                </div>
              </div>
            ) : (
              <div className="shadow-sm bg-white rounded p-2">
                <div className="d-flex flex-row py-3 align-content-center">
                  <div>
                    <img
                      className="img-fluid"
                      style={{ maxWidth: "180px" }}
                      src={`${CDN_URL}/CaremeLogo/finallogo1copy.png`}
                    />
                  </div>
                  <div className="align-self-center ">
                    <h4 className="header_font_ _text_color text-left">
                      We're working hard to bring you exciting events and
                      webinars. In the meantime, feel free to explore our past
                      events and resources.
                    </h4>
                    <div className="d-flex flex-wrap">
                      <a
                        href="https://careme.health/blog/"
                        target="_blank"
                        className="blue-link _text_color text_p_ m-2"
                      >
                        <FaBook size={20} /> Dive into Our Blog
                      </a>
                      <a
                        href="https://careme.health/webinars"
                        target="_blank"
                        className="blue-link _text_color text_p_ m-2"
                      >
                        <FaVideo size={20} /> Explore Our Webinar Series
                      </a>
                      <a
                        href="https://careme.health/press"
                        target="_blank"
                        className="blue-link _text_color text_p_ m-2"
                      >
                        <BsNewspaper size={20} /> Read Our Press Releases
                      </a>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <p style={{ opacity: 0.7 }} className="text_p_ _text_color">
                    Stay Updated with Our Social Media:
                  </p>
                  <div className="social-icons">
                    <a
                      className="m-1"
                      target="_blank"
                      href="https://www.facebook.com/caremehealth"
                    >
                      <FaFacebookSquare
                        size={18}
                        style={{ color: "#1877F2" }}
                      />
                    </a>
                    <a
                      className="m-1"
                      target="_blank"
                      href="https://twitter.com/careme_health"
                    >
                      <FaTwitterSquare size={18} style={{ color: "#1DA1F2" }} />
                    </a>
                    <a
                      className="m-1"
                      target="_blank"
                      href="https://www.instagram.com/careme.health/"
                    >
                      <FaInstagramSquare
                        size={18}
                        style={{ color: "#C13584" }}
                      />
                    </a>
                    <a
                      className="m-1"
                      target="_blank"
                      href="https://www.youtube.com/channel/UCeFSGKESE0eFlHW5xyiaryQ?themeRefresh=1"
                    >
                      <FaYoutubeSquare size={18} style={{ color: "#FF0000" }} />
                    </a>
                    <a
                      className="m-1"
                      target="_blank"
                      href="https://in.linkedin.com/company/caremehealth"
                    >
                      <FaLinkedin size={18} style={{ color: "#0A66C2" }} />
                    </a>
                    <a
                      className="m-1"
                      target="_blank"
                      href="https://in.pinterest.com/caremehealth/"
                    >
                      <FaPinterestSquare
                        size={18}
                        style={{ color: "#E60023" }}
                      />
                    </a>
                    <a
                      className="m-1"
                      target="_blank"
                      href="https://caremehealth.medium.com/"
                    >
                      <FaMedium size={18} style={{ color: "#12100E" }} />
                    </a>
                  </div>
                </div>
              </div>
            )}

            <div
              style={{ borderRadius: "6px" }}
              className="draw shadow-sm bg-white ml-md-3 col-md-4 mt-2 mt-md-0 p-2 px-3"
            >
              {eventsprev_ && eventsprev_.length > 0 && (
                <div className="">
                  <h5
                    style={{ opacity: 0.5, fontSize: "14px" }}
                    className="text_p_ pl-2 pt-2 font-weight-bold _text_color"
                  >
                    POPULAR EVENTS
                  </h5>
                  <div
                    onClick={() => {
                      window.open(
                        `https://careme.health/webinar/${eventsprev_[0].webinar_id}`
                      );
                    }}
                    className="d-flex flex-row mt-3"
                  >
                    <img
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "fill",
                      }}
                      className="rounded-circle"
                      src={eventsprev_[0].banner_image}
                    />
                    <div>
                      <h5 className="header_font_ ml-3 mt-3 _text_color">
                        {eventsprev_[0].title}
                      </h5>
                      <p
                        style={{ opacity: 0.6 }}
                        className="text_p_ ml-3 _text_color mt-n1"
                      >
                        {moment(
                          eventsprev_[0].webinar_at,
                          "DD/MM/YYYY hh:mm A"
                        ).format("Do MMMM YYYY, h.mm A")}
                      </p>
                    </div>
                  </div>
                  <hr className="dotted-line my-0" />
                  <div
                    onClick={() => {
                      window.open(
                        `https://careme.health/webinar/${eventsprev_[1].webinar_id}`
                      );
                    }}
                    className="d-flex flex-row "
                  >
                    <img
                      style={{
                        width: "50px",
                        height: "50px",
                        objectFit: "fill",
                      }}
                      className="rounded-circle"
                      src={eventsprev_[1].banner_image}
                    />
                    <div>
                      <h5 className="header_font_ ml-3 mt-3 _text_color">
                        {eventsprev_[1].title}
                      </h5>
                      <p
                        style={{ opacity: 0.6 }}
                        className="text_p_ ml-3 _text_color mt-n1"
                      >
                        {moment(
                          eventsprev_[1].webinar_at,
                          "DD/MM/YYYY hh:mm A"
                        ).format("Do MMMM YYYY, h.mm A")}
                      </p>
                    </div>
                  </div>
                  <div style={{ maxWidth: "90%" }} className="ml-2 my-3">
                    <button
                      onClick={() => {
                        window.open("https://careme.health/webinars");
                      }}
                      style={{
                        background: "#ddefe5",
                        color: "#215352",
                        border: "none",
                      }}
                      className="btn-block py-2 rounded font-weight-bold"
                    >
                      View All Events <ArrowRight />{" "}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {products && (
          <div className="col-md-10 mx-auto mt-5 p-1">
            <h3 className="font-weight-bold ml-md-5 pl-md-3 _text_color">
              Your Care
            </h3>
            <div className="d-flex flex-md-row flex-column ml-md-5 justify-content-between px-md-0 px-3">
              <div
                onClick={() =>
                  this.goToPayment(products && products[0], "APP-PROD-CAT-1")
                }
                className="shadow-sm d-flex flex-column col-md-3 p-2 ml-md-5 ml-n2 mb-2 mt-md-3 mt-4 bg-white rounded position-relative"
              >
                <img
                  className="img-fluid text-center mt-4"
                  style={{ maxHeight: "55px" }}
                  src={
                    `${CDN_URL}/Websiteicons/NewHomepage/4set/Individ.png`
                  }
                />
                <h3 className="text-center header_font_ _text_color mt-3">
                  Individual
                </h3>
                <p className="text-center text_p_ _text_color">For Myself</p>
                <button
                  style={{
                    background: "#ddefe5",
                    color: "#215352",
                    border: "none",
                  }}
                  className="py-2 font-weight-bold"
                >
                  Book Now
                </button>
              </div>

              <div
                onClick={() =>
                  this.goToPayment(products && products[1], "APP-PROD-CAT-2")
                }
                className="shadow-sm d-flex flex-column col-md-3 p-2 ml-md-3 ml-n2 mb-2 mt-md-3 mt-2 bg-white rounded position-relative"
              >
                <img
                  src={
                    `${CDN_URL}/Websiteicons/NewHomepage/4set/Couplee.png`
                  }
                  className="img-fluid text-center mt-4"
                  style={{ maxHeight: "55px" }}
                />
                <h3 className="text-center header_font_ _text_color mt-3">
                  Couple
                </h3>
                <p className="text-center text_p_ _text_color">
                  For Me and my partner
                </p>
                <button
                  style={{
                    background: "#ddefe5",
                    color: "#215352",
                    border: "none",
                  }}
                  className="py-2 font-weight-bold"
                >
                  Book Now
                </button>
              </div>

              <div
                onClick={() =>
                  this.goToPayment(products && products[2], "APP-PROD-CAT-3")
                }
                className="shadow-sm d-flex flex-column col-md-3 p-2 ml-md-3 ml-n2 mb-2 mt-md-3 mt-2 bg-white rounded position-relative"
              >
                <img
                  src={
                    `${CDN_URL}/Websiteicons/NewHomepage/4set/Psychitry.png`
                  }
                  className="img-fluid text-center mt-4"
                  style={{ maxHeight: "55px" }}
                />
                <h3 className="text-center header_font_ _text_color mt-3">
                  Psychiatry
                </h3>
                <p className="text-center text_p_ _text_color">Medication</p>
                <button
                  style={{
                    background: "#ddefe5",
                    color: "#215352",
                    border: "none",
                  }}
                  className="py-2 font-weight-bold"
                >
                  Book Now
                </button>{" "}
              </div>

              <div
                onClick={() => this.joinFreeChat()}
                className="shadow-sm ml-md-3 ml-n2 d-flex  flex-column col-md-3 p-2 mb-2 mt-md-3 mt-2 bg-white rounded position-relative"
              >
                <img
                  src={
                   `${CDN_URL}/Websiteicons/NewHomepage/4set/coach.png`
                  }
                  className="img-fluid text-center mt-4"
                  style={{ maxHeight: "55px" }}
                />
                <h3 className="text-center header_font_ _text_color mt-3">
                  Coach Chat
                </h3>
                <p className="text-center text_p_ _text_color">
                  24 x 7 Support
                </p>
                <button
                  style={{
                    background: "#ddefe5",
                    color: "#215352",
                    border: "none",
                  }}
                  className="py-2 font-weight-bold"
                >
                  Book Now
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(SelectedService);

