import React from "react";
import forarr from "../../../assets/images/forarr.png";
import { Component } from "react";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import moment from "moment";
import {
  CDN_URL,
  REACT_APP_API_URL,
  REACT_APP_BASE_URL,
  SITE_URL,
} from "../../processENV";
import { RescheduleAction } from "../../../Actions/RescheduleAction";
import Store from "../../../Store";
import { ScheduleAction } from "../../../Actions/ScheduleAction";
import { ScheduleApp } from "../../../Actions/ScheduleApp";
import { TherapyJourneyAction } from "../../../Actions/TherapyJourneyAction";
import { withRouter } from "react-router";
import { Button, Modal, ModalBody, ModalFooter } from "react-bootstrap";
import { BsCheck2Circle, BsCircle } from "react-icons/bs";
import { postapi } from "../../../Helpers/Api";
const publicIp = require("react-public-ip");

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
class Therapy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: null,
      meetingId: "",
      profiledocname: "",
      serverTime: "",
      cancel: "",
      audioLock: false,
      confirmAppointment: [],
      pending: [],
      followUp: [],
      products: [],
      successfulPayment: [],
      therapyForms: [],
      chat_item: [],
      selectP: "",
      old: false,
      new: false,
      empDetails: "",
      _fol_therapist: false,
    };
  }

  componentDidMount() {
    this.getSelectedAppointment();
    this.getNewProd();
    this.getFollowUp();
    this.getPending();
    this.getPaid();
    this.getChat();
    this.getTherapyAsses();
    this.interval = setInterval(() => this.getOrgTime(), 2000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getChat = async () => {
    let data = { patient_n_key: localStorage._patientKey };
    fetch(REACT_APP_API_URL + "/app_chathome/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("chat_det", res);
        if (res.status === "success") {
          this.setState({ chat_item: res });
        } else {
          console.log("meeting id", res.data);
        }
      });
  };

  async joinFreeChat() {
    const pt_key = localStorage._patientKey;
    const JsonData = {
      patient_n_key: localStorage._patientKey,
    };
    fetch(REACT_APP_API_URL + "/counselling_room/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(JsonData),
    })
      .then((response) => response.json())
      .then(async (res) => {
        if (res.status === "success") {
          let JsonData1 = {
            channelName: res.channelid,
            uid: pt_key,
          };
          fetch(REACT_APP_API_URL + "/agora_rtctoken/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
            },
            body: JSON.stringify(JsonData1),
          })
            .then((response) => response.json())
            .then(async (resp) => {
              if (resp.status == "success") {
                localStorage.uid = pt_key;
                localStorage.channelName = res.channelid;
                this.props.history.push("/chatscreen", {
                  channelId: res.channelid,
                  token: resp.rtm_data,
                  uid: pt_key,
                  typeofchat: "CounsellingRoom",
                  freeChat: res,
                  firstChat: res.new_user,
                  doctor_photo:
                    this.state.chat_item &&
                    this.state.chat_item?.support_chat &&
                    this.state.chat_item?.support_chat.length !== 0
                      ? this.state.chat_item?.support_chat[0]?.doctor_photo
                      : null,
                  doctor_name:
                    this.state.chat_item &&
                    this.state.chat_item?.support_chat &&
                    this.state.chat_item?.support_chat.length !== 0
                      ? this.state.chat_item.support_chat[0]?.doctor_name
                      : null,
                  disable:
                    this.state.chat_item &&
                    this.state.chat_item?.support_chat &&
                    this.state.chat_item?.support_chat.length !== 0
                      ? this.state.chat_item.support_chat[0]?.expired
                      : false,
                });
              }
            });
        }
      });
  }
  getTherapyAsses = () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
    };

    fetch(`${REACT_APP_API_URL}/home_therapyforms/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          console.log(response);
          this.setState({ therapyForms: response.data });
        }
      });
  };

  getOrgTime = async () => {
    fetch(`${REACT_APP_API_URL}/org_current_time/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          this.setState({ serverTime: response.data });
        }
      });
  };
  dateConversion = (rdate) => {
    let date = new Date(rdate);
    // console.log("dateee",date.getDate() + " " + monthNames[date.getMonth()]  + "," +  date.getFullYear()  + "at" +  date.getHours() + ":" + (date.getMinutes() == "0" ? date.getMinutes() + "0" :date.getMinutes()) )
    return (
      date.getDate() +
      " " +
      monthNames[date.getMonth()] +
      "," +
      date.getFullYear() +
      " at " +
      date.getHours() +
      ":" +
      (date.getMinutes() == "0" ? date.getMinutes() + "0" : date.getMinutes()) +
      " " +
      (parseInt(date.getHours()) < 12 ? "AM" : " PM")
    );
  };

  dateConversionTherapy = (rdate) => {
    let date = new Date(rdate);
    // console.log("dateee",date.getDate() + " " + monthNames[date.getMonth()]  + "," +  date.getFullYear()  + "at" +  date.getHours() + ":" + (date.getMinutes() == "0" ? date.getMinutes() + "0" :date.getMinutes()) )
    return (
      date.getDate() +
      " " +
      monthNames[date.getMonth()] +
      "," +
      date.getFullYear()
    );
  };

  cancelAppointment = (aKey, reason) => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
      appointment_n_key: aKey,
      status: "Cancelled",
      cancelled_reason: reason,
    };
    console.log(data);
    fetch(`${REACT_APP_API_URL}/app_cancel_appoint/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("cancel appointment", res.data);
        if (res.status === "success") {
          this.getSelectedAppointment();
        } else {
          console.log("cancel appointment", res.data);
        }
      });
  };
  scheduleApp = async(aKey, id, key) => {
    let item = key;
    console.log(aKey, id, key);
    if (item.app_prod_grp_key) {
      let data = {
        patient_n_key: localStorage._patientKey,
        app_prod_grp_key: item.app_prod_grp_key,
      };
      const res = await postapi('/previous_check/',data)
          if (res.status == "success") {
            if (res.data[0].is_active == 1) {
              this.setState({ _fol_therapist: false });
              let appointmentDetail = {
                doc_app_id: res.data[0].employee_n_key,
                app_prod_grp_key: item.app_prod_grp_key,
                app_payment_n_key: item.app_payment_n_key,
                addon: item.addon || false,
              };
              Store.dispatch(
                ScheduleApp({
                  data1: appointmentDetail,
                })
              );
              this.props.history.push(`/scheduleapp/followup/id`, {
                data1: appointmentDetail,
              });
            } else {
              this.props.history.push("/creditsleft", {
                selectP: item,
                creditModal: true,
                empDetails: res.data,
                _fol_therapist: true,
              });
            }
          } else {
            Store.dispatch(
              ScheduleApp({
                data1: item,
              })
            );
            this.props.history.push(`/scheduleapp/followup/${id}`, {
              data1: item,
            });
          }
    }
  };
  rescheduleAppointment = (item) => {
    if(item.overall_status==="Rescheduled"){
      Swal.fire({
        html: `<p style="font-size: 14px; line-height: 1.5;">
                 Appointment can be rescheduled once!<br>
                 Please contact support for further help in appointment booking.
               </p>`
      });  
     }
    else{
    Store.dispatch(
      RescheduleAction({
        prodId: item,
      })
    );
    this.props.history.push(`/reschedule/appointment/date`, { data: item });
  }
  };
  getStep=(title)=>{
    return 0
  }
  scheduleAppointment = async(item) => {
    console.log(item);
    if (item.app_prod_grp_key) {
      let data = {
        patient_n_key: localStorage._patientKey,
        app_prod_grp_key: item.app_prod_grp_key,
      };
      const res= await postapi('/previous_check/',data)
          if (res.status == "success") {
            if (res?.data[0]?.is_active !== 1) {
              let appointmentDetail = {
                doc_app_id: res.data[0].employee_n_key,
                app_prod_grp_key: item.app_prod_grp_key,
                app_payment_n_key: item.app_payment_n_key,
                previous_book: "Yes",
                addon: item.addon || false,
              };
              Store.dispatch(
                ScheduleApp({
                  data1: appointmentDetail,
                })
              );
              this.props.history.push(`/scheduleapp/followup/id`, {
                data1: appointmentDetail,
              });
            } 
            else {
              this.props.history.push("/creditsleft", {
                selectP: item,
                creditModal: true,
                empDetails: res.data,
              });
            }
          } 
          else {
              Store.dispatch(
                ScheduleAction({
                  scheduleStep: this.getStep(item.title),
                  scheduleCurrentStep: this.getStep(item.title),
                  problem: this.getApprodKey(item.title),
                  mode: "Video",
                  data1: item,
                })
              );
            this.props.history.push("/schedule/appointment", { data1: item });
          }
    }
  };
  sessionPay = async(item, index) => {
    console.log(item)
    let data = {
      patient_n_key: localStorage._patientKey,
      app_prod_grp_key: item.app_prod_grp_key,
    };
    const res = await postapi('/previous_check/',data)
        if (res.status == "success") {
          if (res.data[0].is_active == 1) {
            Store.dispatch(
              TherapyJourneyAction({
                indx: index,
              })
            );
            localStorage.setItem("_i", index);
            this.props.history.push(`/therapyjourney/selectSession/`);
          }
        else{
          this.props?.history?.push('/therapistleft',{data:item,empDetails:res.data})
        }
        }
  };
  getNewProd = async () => {
    let data = {
      ip: (await publicIp.v4()) || "",
    };
    fetch(`${REACT_APP_API_URL}/products_groups/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("new prod", res.data);
        if (res.status === "success") {
          this.setState({ products: res.data });
        } else {
          console.log("new prod", res.data);
        }
      });
  };
  getFollowUp = () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
    };

    fetch(`${REACT_APP_API_URL}/upcoming_recommended/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("followup service", res.data);
        if (res.status === "success") {
          this.setState({ followUp: res.data });
        } else {
          console.log("followup service", res.data);
        }
      });
  };
  getPaid = () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
    };

    fetch(`${REACT_APP_API_URL}/upcoming_payment/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("paid service", res.data);
        if (res.status === "success") {
          this.setState({ successfulPayment: res.data });
        } else {
          console.log("pending service", res.data);
        }
      });
  };
  getPending = () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
    };

    fetch(`${REACT_APP_API_URL}/upcoming_followups/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("pending service", res.data);
        if (res.status === "success") {
          this.setState({ pending: res.data });
        } else {
          console.log("pending service", res.data);
        }
      });
  };
  getApprodKey=(title)=>{
    switch (title) {
      case "Couple Therapy":
        return { app_prod_n_key: "APP_PROD-5" };
      case "Psychiatric Consultation":
        return { app_prod_n_key: "APP_PROD-3" };
      default:
        return null;
    }
  }
  getSelectedAppointment = () => {
    let data = {
      patient_n_key: localStorage.getItem("_patientKey"),
    };

    fetch(`${REACT_APP_API_URL}/upcoming_appoints/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then(async (res) => {
        console.log("selected service", res.data);
        if (res.status === "success") {
          this.setState({ confirmAppointment: res.data });
        } else {
          console.log("selected service", res.data);
        }
      });
  };
  changeAudio() {
    const timer = setTimeout(() => {
      this.setState({ audioLock: false });
    }, 240000);
    return () => clearTimeout(timer);
  }
  getMeetingid = (item, id) => {
    if (item.mode_of_telehealth === "Video") {
      let data = {
        patient_n_key: localStorage.getItem("_patientKey"),
        appointment_n_key: id,
      };

      fetch(`${REACT_APP_API_URL}/join_now_app/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then(async (res) => {
          console.log("meeting id", res.data);
          if (res.status === "success") {
            this.setState({ meetingId: res.data });
            let id = res.data.split("/")[4];
            console.log("meeting Id", id);
            window.location.replace(`${REACT_APP_BASE_URL}/patient/` + id);
          } else {
            console.log("meeting id", res.data);
          }
        });
    }
    if (item.mode_of_telehealth === "Audio") {
      this.setState({ audioLock: true }, () => {
        this.changeAudio();
      });
      let data = {
        org_n_key: "ORGID-1",
        appointment_n_key: id,
      };
      fetch(`https://getehr.curegrid.co/api/connect_audio/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then(async (res) => {
          if (res.status === "success") {
            console.log("audio id", res);
          } else {
            console.log("meeting id", res.data);
          }
        });
    }
    if (item.mode_of_telehealth === "Chat") {
      var now_time = "";
      fetch(`${REACT_APP_API_URL}/org_current_time/`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
        },
      })
        .then((response) => response.json())
        .then((response) => {
          if (response.status === "success") {
            now_time = response.data;
            var meetingTime = "";
            meetingTime = item.appointment_date;
            const currentTime = moment(response.data).toDate();
            console.log("This_is_current_time", currentTime);
            console.log(moment(this.state.serverTime).toDate());
            var meetingDate = moment(meetingTime).toDate();
            let differenceTIme =
              (meetingDate.getTime() - currentTime.getTime()) / 1000; //- (19800) //minus offset time 330 mins *60
            var differenceSeconds = parseInt(differenceTIme) / 60;

            if (differenceSeconds <= 10) {
              let JsonData1 = {
                channelName: item.appointment_n_key,
                uid: localStorage._patientKey,
              };
              fetch(REACT_APP_API_URL + "/agora_rtctoken/", {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "a7143772a09f993125901ea752bfd4fcf4b31d29",
                },
                body: JSON.stringify(JsonData1),
              })
                .then((response) => response.json())
                .then(async (resp) => {
                  if (resp.status == "success") {
                    localStorage.uid = localStorage._patientKey;
                    localStorage.channelName = item.appointment_n_key;
                    this.props.history.push("/chatscreen", {
                      channelId: item.appointment_n_key,
                      empKey: item?.doc_app_id,
                      token: resp.rtm_data,
                      uid: item.patient_n_key,
                      mode: item.mode_of_telehealth,
                      typeofchat: "TeleHealth",
                      planitem: item,
                      doctor_photo: item.doctor_profile,
                      doctor_name: item.doctor_name,
                    });
                  }
                });
            } else {
              this.props.history.push("/waitingscreen", {
                item: item,
                typeofchat: "TeleHealth",
              });
            }
          }
        });
    }
  };
  cancelReason = async (item) => {
    const { value: text } = await Swal.fire({
      input: "textarea",
      inputLabel: "Could you tell us the reason for cancellation",
      inputPlaceholder: "Type your message here...",
      inputAttributes: {
        "aria-label": "Type your message here",
      },
      customClass: {
        validationMessage: "my-validation-message",
      },
      showCancelButton: true,
      preConfirm: (value) => {
        if (value.length <= 15) {
          Swal.showValidationMessage(
            " Reason should be atleast 15 characters!"
          );
        } else {
          this.cancelAppointment(item && item.appointment_n_key, value);
        }
      },
    });
  };

  showbutton = (item) => {
    let nowDate = moment(this.state.serverTime).toDate();
    let appDate = moment(item.appointment_date).toDate();
    var diff = (appDate.getTime() - nowDate.getTime()) / 1000;
    var differenceSeconds = parseInt(diff) / 60;
    if (differenceSeconds >= 60) {
      return true;
    } else return false;
  };
  showAppointment = (item) => {
    let nowDate = moment(this.state.serverTime).toDate();
    let appDate = moment(item.appointment_date).toDate();
    var diff = (appDate.getTime() - nowDate.getTime()) / 1000;
    var differenceSeconds = parseInt(diff) / 60;
    if (differenceSeconds >= -180) {
      return true;
    } else return false;
  };

  goToPayment = async (i, title) => {
    const { confirmAppointment, successfulPayment, followUp, pending } =
      this.state;
    let k_ = localStorage.getItem("_patientKey");
    const ptkey = k_.split("-")[2];
    const initial = "therapy";
    console.log(i, title);
    if (title == "APP-PROD-CAT-1") {
      if (
        confirmAppointment.length !== 0 &&
        confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
          .length !== 0
      ) {
        confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
          .length !== 0
          ? this.getMeetingid(
              confirmAppointment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-1"
              )[0],
              confirmAppointment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-1"
              )[0].appointment_n_key
            )
          : console.log("no_confirmdata");
      } else if (
        successfulPayment.length !== 0 &&
        successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
          .length !== 0
      ) {
        successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
          .length !== 0
          ? this.scheduleAppointment(
              successfulPayment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-1"
              )[0]
            )
          : console.log("no_paydata");
      } else if (
        followUp.length !== 0 &&
        followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
          .length !== 0
      ) {
        followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")
          .length !== 0
          ? this.sessionPay(
              followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")[0],
              0
            )
          : console.log("no_therapydata");
      } else if (
        pending.length !== 0 &&
        pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1").length !==
          0
      ) {
        pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1").length !==
        0
          ? this.scheduleApp(
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")[0]
                .appointment_n_key,
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")[0]
                .doc_app_id,
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-1")[0]
            )
          : console.log("no_sessiondata");
      } else if (
        (pending.length !== 0 &&
          pending?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-1")
          )) ||
        (confirmAppointment.length !== 0 &&
          confirmAppointment?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-1")
          )) ||
        (successfulPayment.length !== 0 &&
          successfulPayment?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-1")
          )) ||
        (followUp.length !== 0 &&
          followUp?.some((i) => i.app_prod_grp_key?.includes("APP-PROD-CAT-1")))
      ) {
        console.log("yes");
      } else {
        // window.location.assign(`http://careme.health/${ptkey}/individual-therapy/${initial}`)
        window.location.assign(
          SITE_URL + `/find/user/${ptkey}/individual-therapy/${initial}`
        );
      }
    }
    if (title == "APP-PROD-CAT-2") {
      if (
        confirmAppointment.length !== 0 &&
        confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
          .length !== 0
      ) {
        confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
          .length !== 0
          ? this.getMeetingid(
              confirmAppointment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-2"
              )[0],
              confirmAppointment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-2"
              )[0].appointment_n_key
            )
          : console.log("no_confirmdata");
      } else if (
        successfulPayment.length !== 0 &&
        successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
          .length !== 0
      ) {
        successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
          .length !== 0
          ? this.scheduleAppointment(
              successfulPayment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-2"
              )[0]
            )
          : console.log("no_paydata");
      } else if (
        followUp.length !== 0 &&
        followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
          .length !== 0
      ) {
        followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")
          .length !== 0
          ? this.sessionPay(
              followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")[0],
              0
            )
          : console.log("no_therapydata");
      } else if (
        pending.length !== 0 &&
        pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2").length !==
          0
      ) {
        pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2").length !==
        0
          ? this.scheduleApp(
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")[0]
                .appointment_n_key,
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")[0]
                .doc_app_id,
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-2")[0]
            )
          : console.log("no_sessiondata");
      } else if (
        (pending.length !== 0 &&
          pending?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-2")
          )) ||
        (confirmAppointment.length !== 0 &&
          confirmAppointment?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-2")
          )) ||
        (successfulPayment.length !== 0 &&
          successfulPayment?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-2")
          )) ||
        (followUp.length !== 0 &&
          followUp?.some((i) => i.app_prod_grp_key?.includes("APP-PROD-CAT-2")))
      ) {
        console.log("yes");
      } else {
        window.location.assign(
          SITE_URL + `/find/user/${ptkey}/couple-therapy/${initial}`
        );
      }
    }
    if (title == "APP-PROD-CAT-3") {
      if (
        confirmAppointment &&
        confirmAppointment.length !== 0 &&
        confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
          .length !== 0
      ) {
        confirmAppointment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
          .length !== 0
          ? this.getMeetingid(
              confirmAppointment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-3"
              )[0],
              confirmAppointment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-3"
              )[0].appointment_n_key
            )
          : console.log("no_confirmdata");
      } else if (
        successfulPayment &&
        successfulPayment.length !== 0 &&
        successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
          .length !== 0
      ) {
        successfulPayment.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
          .length !== 0
          ? this.scheduleAppointment(
              successfulPayment.filter(
                (i) => i.app_prod_grp_key == "APP-PROD-CAT-3"
              )[0]
            )
          : console.log("no_paydata");
      } else if (
        followUp &&
        followUp.length !== 0 &&
        followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
          .length !== 0
      ) {
        followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")
          .length !== 0
          ? this.sessionPay(
              followUp.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")[0],
              0
            )
          : console.log("no_therapydata");
      } else if (
        pending &&
        pending.length !== 0 &&
        pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3").length !==
          0
      ) {
        pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3").length !==
        0
          ? this.scheduleApp(
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")[0]
                .appointment_n_key,
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")[0]
                .doc_app_id,
              pending.filter((i) => i.app_prod_grp_key == "APP-PROD-CAT-3")[0]
            )
          : console.log("no_sessiondata");
      } else if (
        (pending.length !== 0 &&
          pending?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-3")
          )) ||
        (confirmAppointment.length !== 0 &&
          confirmAppointment?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-3")
          )) ||
        (successfulPayment.length !== 0 &&
          successfulPayment?.some((i) =>
            i.app_prod_grp_key?.includes("APP-PROD-CAT-3")
          )) ||
        (followUp.length !== 0 &&
          followUp?.some((i) => i.app_prod_grp_key?.includes("APP-PROD-CAT-3")))
      ) {
        console.log("yes");
      } else {
        window.location.assign(
          SITE_URL + `/find/user/${ptkey}/psychiatric-consultation/${initial}`
        );
      }
    }
  };

  render() {
    if (
      this.state.doctor_profile === "" ||
      this.state.doctor_profile === null
    ) {
      var docname = this.state.doctor_name;
      var res = docname.charAt(0);
      this.setState({
        profiledocname: res,
      });
    }
    let {
      confirmAppointment,
      successfulPayment,
      creditModal,
      products,
      followUp,
      pending,
      therapyForms,
    } = this.state;

    return (
      <div
        className="col-md-7 mx-auto pl-md-5 pr-md-0"
        style={{ overflowY: "scroll", marginTop: 20, marginBottom: 40 }}
      >
        <Modal centered lg show={creditModal}>
          <ModalBody style={{ textAlign: "center" }}>
            {this.state.empDetails[0] &&
            this.state.empDetails[0].is_active == 0 ? (
              <div className="prec_check_ text_p_ font-weight-bold pt-5">
                Hey, we recently had to let our therapist{" "}
                {`${
                  (this.state.empDetails[0] &&
                    this.state.empDetails[0].first_name) +
                  " " +
                  (this.state.empDetails[0] &&
                    this.state.empDetails[0].last_name)
                }`}{" "}
                go. If you would like to continue with her/him, send an email{" "}
                <span style={{ textDecorationLine: "underline" }}>
                  {this.state.empDetails[0] &&
                    this.state.empDetails[0].email_id}
                </span>{" "}
                or call{" "}
                <span style={{ textDecorationLine: "underline" }}>
                  {this.state.empDetails[0] &&
                    this.state.empDetails[0].phone_number}{" "}
                </span>
                <br />
                <div>
                  <br />
                  <p className="text_p_">
                    You're not alone and we're here for you. We have therapists
                    who can help you rebuild your life, learn and grow from the
                    experience, and get back to where you were before this
                    happened. Please reach out if you are ready to begin.
                  </p>
                </div>
                <br />
                <span style={{ fontSize: 12 }}>
                  Our support number: +91 7829-002-002
                </span>
                <div
                  style={{ width: "70%" }}
                  onClick={() => this.setState({ old: false, new: true })}
                  className="prec_check mt-4 border rounded text-center mx-auto py-3"
                >
                  {this.state.new ? (
                    <BsCheck2Circle
                      size={25}
                      style={{ marginRight: "4px" }}
                      className="icn_span"
                    />
                  ) : (
                    <BsCircle
                      style={{ marginRight: "4px" }}
                      size={15}
                      className="icn_span"
                    />
                  )}{" "}
                  I would like to switch therapist
                </div>
              </div>
            ) : (
              <div
                onClick={() => this.setState({ old: true, new: false })}
                className="prec_check mt-4 border rounded"
              >
                {this.state.old ? (
                  <BsCheck2Circle size={30} className="icn_span" />
                ) : (
                  <BsCircle size={15} className="icn_span" />
                )}{" "}
                I would like to continue with my previous therapist
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              onClick={() => this.setState({ creditModal: false })}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              disabled={this.state.old || this.state.new ? false : true}
              onClick={() => {
                this.setState({ creditModal: false });
                if (this.state.old) {
                  console.log("d");
                }
                if (this.state.new) {
                  if (this.state._fol_therapist) {
                    Store.dispatch(
                      ScheduleAction({
                        data1: this.state.selectP,
                      })
                    );
                    this.props.history.push("/schedule/appointment", {
                      data1: this.state.selectP,
                      _fol_therapist: true,
                    });
                  } else {
                    if (
                      this.state.selectP.title === "Psychiatric Consultation"
                    ) {
                      let selectedProblem = { app_prod_n_key: "APP_PROD-3" };
                      Store.dispatch(
                        ScheduleAction({
                          scheduleStep: 1,
                          scheduleCurrentStep: 1,
                          problem: selectedProblem,
                          mode: "Video",
                          data1: this.state.selectP,
                        })
                      );
                    } else {
                      Store.dispatch(
                        ScheduleAction({
                          data1: this.state.selectP,
                        })
                      );
                    }
                    this.props.history.push("/schedule/appointment", {
                      data1: this.state.selectP,
                    });
                  }
                }
              }}
            >
              Continue
            </Button>{" "}
          </ModalFooter>
        </Modal>

        {confirmAppointment.length !== 0 &&
          confirmAppointment.map((item, index) => {
            return (
              this.showAppointment(item) && (
                <div className="col-md-8 mx-auto draw shadow rounded p-4 bg-white mt-5 mb-3">
                  <div className="row px-3 text-center">
                    <img
                      style={{ width: "65px", height: "65px" }}
                      src={
                        `${CDN_URL}/APP/appointment.png`
                      }
                    />
                    <h3
                      className="pl-1 pt-4"
                      style={{
                        fontFamily: "Source Serif Pro",
                        fontSize: "18px",
                      }}
                    >
                      Appointment Confirmed!
                    </h3>
                  </div>
                  <div className="row px-3 mt-2">
                    <p className="text_p_ text_medium_">
                      We're looking forward to speaking with you,{" "}
                      {item.patient_name}! Your upcoming session has been
                      scheduled at{" "}
                      {this.dateConversion(item && item.appointment_date)} with{" "}
                      {item.doctor_name}
                    </p>
                  </div>
                  <div className="row px-3 text-center mt-2 d-flex flex-row justify-content-center">
                    {item.mode_of_telehealth === "Video" && (
                      <button
                        className={`btn  m-1 ${
                          !this.showbutton(item) ? "btn-block" : ""
                        } ${
                          !this.showbutton(item)
                            ? "btn_Schdule"
                            : "btn_Schdules"
                        }`}
                        onClick={() =>
                          this.getMeetingid(
                            item,
                            item && item.appointment_n_key
                          )
                        }
                      >
                        Join Call
                      </button>
                    )}
                    {item.mode_of_telehealth === "Audio" && (
                      <button
                        className={`btn m-1 ${
                          !this.showbutton(item) ? "btn-block" : ""
                        } ${
                          !this.showbutton(item)
                            ? "btn_Schdule"
                            : "btn_Schdules"
                        }`}
                        onClick={() =>
                          this.getMeetingid(
                            item,
                            item && item.appointment_n_key
                          )
                        }
                      >
                        Call Now
                      </button>
                    )}
                    {item.mode_of_telehealth === "Chat" && (
                      <button
                        className={`btn  m-1 ${
                          !this.showbutton(item) ? "btn-block" : ""
                        } ${
                          !this.showbutton(item)
                            ? "btn_Schdule"
                            : "btn_Schdules"
                        }`}
                        onClick={() =>
                          this.getMeetingid(
                            item,
                            item && item.appointment_n_key
                          )
                        }
                      >
                        Join Chat
                      </button>
                    )}
                    {this.showbutton(item) && (
                      <button
                        className="btn btn_Schdules m-1"
                        style={{ fontSize: "10px" }}
                        onClick={() => this.rescheduleAppointment(item && item)}
                      >
                        Reschedule
                      </button>
                    )}
                    {this.showbutton(item) && (
                      <button
                        className="btn btn_Schdules m-1"
                        style={{ fontSize: "10px" }}
                        onClick={() => this.cancelReason(item)}
                      >
                        Cancel
                      </button>
                    )}
                  </div>
                </div>
              )
            );
          })}

        {confirmAppointment.length == 0 &&
          successfulPayment.length == 0 &&
          pending.length !== 0 && (
            <h2 className="upcome_appoint mt-5">
              {" "}
              Book your Follow-up Session
            </h2>
          )}
        {confirmAppointment.length == 0 &&
          successfulPayment.length == 0 &&
          pending.length !== 0 &&
          pending.map((item, index) => {
            return (
              <div className="col-md-7 mx-auto draw shadow rounded p-4 bg-white mt-5 mb-3">
                <div className="row px-3 text-center">
                  <img
                    style={{ width: "35px", height: "35px" }}
                    src={
                      `${CDN_URL}/APP/calendar1.png`
                    }
                  />
                  <h3
                    className="pt-2 pl-2"
                    style={{ fontFamily: "Source Serif Pro" }}
                  >
                    <b>{item.remaining_session}</b> Upcoming Sessions
                  </h3>
                </div>
                <div className="row px-3 mt-2">
                  <p className="text_p_ text_medium_">
                    That's Awesome! you have completed{" "}
                    {item.total_session - item.remaining_session} sessions out
                    of {item.total_session}, let's progress towards your goals,
                    re-evaluating along the way-by your therapist!
                  </p>
                </div>
                <div className="row px-3 text-center mt-2">
                  <button
                    className="btn btn-block btn_Schdule"
                    onClick={() =>
                      this.scheduleApp(
                        item && item.appointment_n_key,
                        item.doc_app_id,
                        item
                      )
                    }
                  >
                    Schedule Now
                  </button>
                </div>
              </div>
            );
          })}
        {confirmAppointment.length == 0 &&
          successfulPayment.length == 0 &&
          pending.length == 0 &&
          followUp.length !== 0 &&
          followUp.map((item, index) => {
            return (
              <div className="col-md-7 mx-auto draw shadow rounded p-4 bg-white mt-5 mb-3">
                <div className="row px-3 text-center">
                  <img
                    style={{ width: "55px", height: "55px" }}
                    src={
                      `${CDN_URL}/APP/app/measring.png`
                    }
                  />
                  <h3
                    className="pt-3 pl-2"
                    style={{ fontFamily: "Source Serif Pro" }}
                  >
                    Book Your Folloup
                  </h3>
                </div>
                <div className="row px-3 mt-2">
                  <p className="text_p_ text_medium_">
                    Your therapist {item.doctor_name} has crafted a customized
                    care plan for you! Now You can work with a therapist to
                    practice standing your ground, speaking up, or improving
                    relationships, all of which can improve your quality of
                    life.
                  </p>
                </div>
                <div className="row px-3 text-center mt-2">
                  <button
                    className="btn btn-block btn_Schdule"
                    onClick={() => this.sessionPay(item, index)}
                  >
                    View my care plan
                  </button>
                </div>
              </div>
            );
          })}
        {confirmAppointment.length == 0 &&
          successfulPayment.length !== 0 &&
          successfulPayment.map((item, index) => {
            return (
              <div className="col-md-7 mx-auto draw shadow rounded p-4 bg-white mt-5 mb-3">
                <div className="row px-3 text-center">
                  <img
                    style={{ width: "65px", height: "65px" }}
                    src={
                      `${CDN_URL}/APP/appointment.png`
                    }
                  />
                  <h3
                    className="pt-3 pl-2"
                    style={{ fontFamily: "Source Serif Pro" }}
                  >
                    <b>{item.remaining_session}</b> Book your session
                  </h3>
                </div>
                <div className="row px-3 mt-2">
                  <p className="text_p_ text_medium_">
                    We've received your payment and our therapist is waiting for
                    you, click book now to book your appointment
                  </p>
                </div>
                <div className="row px-3 text-center mt-2">
                  <button
                    className="btn btn-block btn_Schdule"
                    onClick={() => this.scheduleAppointment(item && item)}
                  >
                    Book Now
                  </button>
                </div>
              </div>
            );
          })}
        <div className="col-md-8 d-flex flex-column mx-auto mt-5 px-3">
          <h3 className="text-center">
            What type of service are you looking for?
          </h3>
          <p className="text-center">
            CareMe offers Confidential care that's accessible, affordable, and
            evidence-based.
          </p>

          <div
            onClick={() =>
              this.goToPayment("Individual Therapy", "APP-PROD-CAT-1")
            }
            className="shadow-sm border d-flex col-md-9 p-2 mx-auto mb-2 mt-3 bg-white rounded position-relative"
          >
            <img
              className="img-fluid ml-md-3"
              style={{ maxHeight: "55px" }}
              src={
                `${CDN_URL}/APP/individual.png`
              }
            />
            <h3 className="pt-4 pb-4 pl-3 h5 ">Book a therapist</h3>
            <img
              className="img-fluid ml-5 mr-n4 position-absolute"
              style={{ maxHeight: "30px", right: 34 }}
              src={forarr}
            />
          </div>
          <div
            onClick={() =>
              this.goToPayment("Couples Therapy", "APP-PROD-CAT-2")
            }
            className="shadow-sm border d-flex col-md-9 p-2 mx-auto mb-2 mt-3 bg-white rounded position-relative"
          >
            <img
              src={
                `${CDN_URL}/APP/couple.png`
              }
              className="img-fluid ml-md-3"
              style={{ maxHeight: "55px" }}
            />
            <h3 className="pt-4 pb-4 pl-3 h5 ">Book a couple therapy</h3>
            <img
              src={forarr}
              className="img-fluid ml-5 mr-n4 position-absolute"
              style={{ maxHeight: "30px", right: 34 }}
            />
          </div>
          <div
            onClick={() => this.goToPayment("Psychiatrist", "APP-PROD-CAT-3")}
            className="shadow-sm border d-flex col-md-9 p-2 mx-auto mb-4 mt-3 bg-white rounded position-relative"
          >
            <img
              src={`${CDN_URL}/APP/stestscope12%20copy.png`}
              className="img-fluid ml-md-3"
              style={{ maxHeight: "55px" }}
            />
            <h3 className="pt-4 pb-4 pl-md-3 pl-1 h5 ">Book a psychiatrist</h3>
            <img
              src={forarr}
              className="img-fluid ml-5 mr-n4 position-absolute"
              style={{ maxHeight: "30px", right: 34 }}
            />
          </div>
          <div
            onClick={() => this.joinFreeChat()}
            className="shadow-sm border d-flex col-md-9 p-2 mx-auto mb-4 mt-3 bg-white rounded position-relative"
          >
            <img
              src={`${CDN_URL}/APP/care%20coach%20copy.png`}
              className="img-fluid ml-md-3"
              style={{ maxHeight: "55px" }}
            />
            <h3 className="pt-4 pb-4 pl-md-3 pl-1 h5 ">Chat with care coach</h3>
            <img
              src={forarr}
              className="img-fluid ml-5 mr-n4 position-absolute"
              style={{ maxHeight: "30px", right: 34 }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Therapy);
