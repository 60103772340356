import React from "react";
import { Provider } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Store from "../Store";
import App from "../App";
import HomePage from "../Components/Layouts/Home/HomePage";
import Profile from "../Components/Layouts/Profile/Profile";
import HomeProfile from "../Components/Layouts/Profile/HomeProfile";
import PrivateRoute from "./PrivateRoute";
import RIndex from "../Components/Layouts/Reschedule/Index";
import SIndex from "../Components/Layouts/Reschedule/Schedule/Index";
import SAIndex from "../Components/Layouts/TherapyJourney/ScheduleAppointment/index";
import TIndex from "../Components/Layouts/TherapyJourney/Index";
import BillingHistory from "../Components/Layouts/Profile/BillingHistory";
import PaymentStatus from "../Components/Layouts/PaymentStatus";
import AppointmentDetails from "../Components/Layouts/AppointmentDetails";
//Meeting files
import Error from "../pages/Error";
import ThankScreen from "../Components/AgoraVideoCall/ThanksScreen";
import QuitScreen from "../Components/AgoraVideoCall/QuitScreen";
import AgoraError from "../Components/AgoraVideoCall/AgoraLocalStreamError";
import PermissionDenied from "../Components/AgoraVideoCall/PermissionDenied";
import ChooseLanguage from "../Components/Layouts/Home/ChooseLanguage";
import SelectDateTime from "../Components/Layouts/TherapyJourney/SelectDateTime";
import TherapyCare from "../Components/Layouts/TherapyJourney/TherapyCare";
import CommunityHome from "../Components/Community/CommunityHome";
import CommentScreen from "../Components/Community/commentScreen";
import Documents from "../Components/Layouts/Profile/Documents";
import Therapy from "../Components/Layouts/Home/Therapy";
import RegistrationForm from "../Components/Layouts/Login/RegistrationForm";
import AuthUser from "../Components/Layouts/Login/AuthUser";
import TherapyAsses from "../Components/Layouts/TherapyAsses";
import Exercise from "../Components/Layouts/Profile/Exercise";
import Goals from "../Components/Layouts/Profile/Goals";
import Prescription from "../Components/Layouts/Profile/Prescription";
import AgoraCanvas from "../Components/AgoraVideoCall";
import ChatDetails from "../Components/Layouts/Home/ChatDetails";
import WaitingScreen from "../Components/AgoraVideoCall/WaitingScreen/WaitingScreen";
import Chatpay from "../Components/Layouts/Home/Chatpay";
import Workbook from "../Components/Layouts/Workbook";
import ResultAssess from "../Components/Layouts/ResultAssess";
import wellbeing from "../Components/Layouts/wellbeing";
import wellbeingprofile from "../Components/Layouts/Profile/wellbeingprofile";
import wellbeintrackenglish from "../Components/Layouts/wellbeintrackenglish";
import NewLogin from "../Components/Layouts/Login/NewLogin";
import CreditPage from "../Components/Layouts/Home/CreditLeft";
import TherapistLeft from "../Components/Layouts/Home/TherapistLeft";
import AssesResults from "../Components/Layouts/AssesResults";
import AssessmentAuth from "../Components/Layouts/Login/AssesmentAuth";

const createHistory = require("history").createBrowserHistory;
export const history = createHistory();

function ProviderComponent() {
  return (
    <Provider store={Store}>
      <Router history={history}>
        <Switch>
          <Route exact={true} path="/" component={App} />
          <PrivateRoute path="/home" component={HomePage} />
          <PrivateRoute path="/profile" component={HomeProfile} />
          <PrivateRoute path="/userProfile" component={Profile} />
          <PrivateRoute
            path="/reschedule/:productId/:empId?"
            component={RIndex}
          />
          <PrivateRoute path="/schedule/:productId" component={SIndex} />
          <PrivateRoute
            path="/scheduleapp/:productId/:empId"
            component={SAIndex}
          />
          <PrivateRoute
            exact={true}
            path="/therapycare"
            component={TherapyCare}
          />
          <PrivateRoute path="/history/billing" component={BillingHistory} />
          <PrivateRoute path="/paymentstatus" component={PaymentStatus} />
          <PrivateRoute path="/changelanguage" component={ChooseLanguage} />
          <PrivateRoute path="/comment/:id" component={CommentScreen} />
          <PrivateRoute path="/user_documents" component={Documents} />
          <PrivateRoute path="/exercise" component={Exercise} />
          <PrivateRoute path="/goals" component={Goals} />
          <PrivateRoute path="/prescription" component={Prescription} />
          <PrivateRoute path="/offerings" component={Therapy} />
          <PrivateRoute path="/billing" component={AppointmentDetails} />
          <PrivateRoute path="/chatscreen" component={AgoraCanvas} />
          <PrivateRoute
            exact={true}
            path="/therapyjourney/selectSession/"
            component={TIndex}
          />
          <PrivateRoute
            exact={true}
            path="/therapyjourney/selectdatetime"
            component={SelectDateTime}
          />
          <PrivateRoute path="/creditsleft" component={CreditPage} />
          <PrivateRoute path="/therapistleft" component={TherapistLeft} />
          <PrivateRoute path="/chatdetails" component={ChatDetails} />
          <PrivateRoute path="/waitingscreen" component={WaitingScreen} />
          <PrivateRoute path="/workbook" component={Workbook} />
          <PrivateRoute
            path="/wellbeing-user-profile"
            component={wellbeingprofile}
          />
          <PrivateRoute
            path="/wellbeing-asses-view"
            component={wellbeintrackenglish}
          />
          <PrivateRoute path="/chatpay" component={Chatpay} />
          <Route exact={true} path="/:id/:id/auth/user" component={AuthUser} />
          <Route exact={true} path="/:id/assessments/:id/:id/auth/user" component={AssessmentAuth} />
          <Route path="/signup" component={NewLogin} />
          <Route path="/wellbeing-profile" component={wellbeing} />
          <Route path="/thanks" component={ThankScreen} />
          <Route path="/quit" component={QuitScreen} />
          <Route path="/permission" component={PermissionDenied} />
          <Route path="/error" component={AgoraError} />
          <Route path="/pendingQues" component={RegistrationForm} />
          <Route path="/therapyAsses" component={TherapyAsses} />
          <Route path="/assesment-result-view" component={AssesResults} />
         
          <Route
            path="/careme/assessment/result/:id"
            component={ResultAssess}
          />
          <Route component={Error} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default ProviderComponent;
